/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff");
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.woff2");
}

.backdrop {
  position: fixed;
    backdrop-filter: blur(10px);
    z-index: 10002;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    transition: opacity 500ms linear, visibility 500ms linear;  

    .popup-window {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #ffffff;
      // padding: 36px;
      max-width: 800px;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
      border-radius: 12px;
      height: auto;
      max-height: 100%;
      overflow: auto;

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        cursor: pointer;
        border: none;
        transition: all .5s ease;
        background-color: #FBFBFB;
        border-radius: 5px;
        transform: translateY(0);

        &:hover {
          transform: translateY(-2px);
        }
      }

      .popup-image {
        min-width: 400px;
        min-height: 400px;
        max-width: 800px;
      }
    }
}

.jedidesk-chat__position {
  position: fixed;
  bottom: 16px;
  z-index: 10001;
  color-scheme: none;

  @media (max-width: 750px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    bottom: -10px;
  }
}

.jedidesk-chat__left {
  left: 20px;
  @media (max-width: 750px) {
    left: 0px;
    transform: scale(0.8);
  }
}

.jedidesk-chat__right {
  right: 20px;
  @media (max-width: 750px) {
    right: 0px;
    transform: scale(0.8);
  }
}

.jedidesk-chat__position--open {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  animation-name: jedidesk-widget-open;
  animation-duration: 0.5s;
  animation-fill-mode: backwards;

  @media (max-width: 750px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000000;
  }
}
.jedidesk-chat__position--open-left {
  @media (min-width: 750px) {
    left: 16px;
  }
}

.jedidesk-chat__position--open-right {
  @media (min-width: 750px) {
    right: 16px;
  }
}

.jedidesk-welkome-timeout-message {
  position: absolute;
  white-space: pre-line;
  bottom: 35px;
  right: 125px;
  width: 205px;
  height: auto;
  padding: 15px 10px 10px 10px;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 100;
  cursor: pointer;

  .jedidesk-welkome-timeout-message-text {
    font-family: "Inter";
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #3a393a;
    margin: 0;
  }

  @media (min-width: 751px) {
    bottom: 10px;
    right: 105px;
  }

  &:after {
    content: "";
    position: absolute;
    border: 12px solid transparent;
    right: -24px;
    bottom: 20px;
    border-top-color: #ffffff;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .jedidesk-welkome-timeout-message-close {
    position: absolute;
    background-color: inherit;
    border: none;
    cursor: pointer;
    color: #a0a0a0;
    right: 5px;
    top: -4px;
    font-size: 24px;
    padding: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: transparent;
    width: 15px !important;
    height: 30px !important;
  }

  @media (max-width: 550px) {
    right: 0;
    bottom: 125px;
    width: 230px;

    &:after {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      right: 50px;
      bottom: -24px;
    }
  }
}

.jedidesk-welkome-timeout-message-left {
  left: 115px;

  &:after {
    right: auto;
    left: -23px;
    top: 25px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    
  }

  @media (max-width: 550px) {
    right: 0;
    bottom: 125px;
    width: 230px;
    left: 10px;

    &:after {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      right: 200px;
    top: 69px;
    }
  }
    }


.jedidesk-frame-position {
  position: relative;
  width: 385px;
  height: 600px;
  z-index: 10;


  // @media (max-height: 1000px) {
  //   height: 600px;
  // }

  // @media (max-height: 620px) {
  //   height: 600px;
  // }

  @media (max-width: 750px) {
    height: 100%;
    width: 100%;
  }

  &--close {
    width: 90px;
    height: 90px;

    @media (max-width: 750px) {
      height: 90px;
      width: 90px;
      -ms-flex-item-align: end;
      align-self: flex-end;
      margin-bottom: 20px;
      margin-right: 20px;
      bottom: 0px !important;
    }
  }

  iframe {
    border: none;
  }
}
///////////////////////////////////////////////////////////// 1 variant 

.hover-element-container {
      position: absolute;
    bottom: 60px;
    right: 0px;
    padding-bottom: 40px;
   opacity: 0;
    pointer-events: none;
   transition: all .5s ease;
}

.hover-element-container-left {
  right: -100px;
}

.jedidesk-dropdown-chats {
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
    background: #FBFBFB;
    width: 170px;
    // height: 145px;
     height: auto;
    -webkit-box-shadow: 0px 4px 88px -17px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 4px 88px -17px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
    z-index: 100;

    .jedidesk-link-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
       -ms-flex-direction: column;
           flex-direction: column;
   padding: 15px 30px 0 30px;
      .jedidesk-dropdown-link {
  width: 100%;
  height: 40px;
  font-family: 'Inter';
font-weight: 700;
font-size: 13px;
line-height: 20px;
color: #4E4E4E;
text-decoration: none;
cursor: pointer;

 svg {
  vertical-align: bottom;
    margin-right: 5px;
    -webkit-transition: -webkit-transform 250ms linear;
    transition: -webkit-transform 250ms linear;
    -o-transition: transform 250ms linear;
    transition: transform 250ms linear;
    transition: transform 250ms linear, -webkit-transform 250ms linear;
  }
}
    }

    & {
      .jedidesk-dropdown-link:hover,
      .jedidesk-dropdown-bottom:hover { 

        svg {
          -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
                  transform: scale(1.1);
        }
        
      }
    }
}

.jedidesk-dropdown-bottom-company-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 34px;
  color: #FFFFFF;
  background: #A1C6FB;
  height: 33px;
  text-decoration: none;
  text-align: center;
  border-radius: 0px 0px 21px 21px;

  .jedidesk-logo-link {
    font-weight: 700;
    font-size: 14px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }
}

.jedidesk-position-logo-container {
  position: relative;
  display: flex;
  height: 34px;

  .jedidesk-dropdown-bottom-second-company-link {
      font-family: "Inter";
      font-style: normal;
      position: absolute;
      font-weight: 500;
      font-size: 11px;
      line-height: 27px;
      color: #FFFFFF;
      background: #A1C6FB;
      width: 110px;
      height: 27px;
      text-decoration: none;
      text-align: center;
      border-radius: 4px;
      margin-top: 10px;
      left: -32px;
          -webkit-transition: -webkit-transform 250ms linear;
            transition: -webkit-transform 250ms linear;
            -o-transition: transform 250ms linear;
            transition: transform 250ms linear;
            transition: transform 250ms linear, -webkit-transform 250ms linear;
  
      .jedidesk-logo-link {
        font-weight: 700;
        font-size: 12px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
      }

      &:hover {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
      }
  }
}



.jedidesk-dropdown-bottom {
  width: 100%;
  border: none;
  background-color: transparent !important;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 5px 10px 5px;
  color: #4E4E4E !important;
  cursor: pointer !important;
  text-transform: none;

  svg {
    vertical-align: bottom;
    margin-left: -6px;
    margin-right: 5px;
     -webkit-transition: -webkit-transform 250ms linear;
     transition: -webkit-transform 250ms linear;
     -o-transition: transform 250ms linear;
     transition: transform 250ms linear;
     transition: transform 250ms linear, -webkit-transform 250ms linear;

  }
}


@media (min-width: 750px) {
  .jedidesk-chat__position:hover {
    .hover-element-container {
    opacity: 1;
    pointer-events: auto;
  }
  }
}


.jedidesk-dropdown-second-links {
  display: flex;
   flex-direction: column;
   position: absolute;
   bottom: 81px;
   left: 39%;
   padding-bottom: 10px;
   opacity: 0;
   transform: translateY(110px);
   transition: all .5s cubic-bezier(.1, -1.5, .1, 2.5);

  .jedidesk-icons-width {
      width: 30px;
      height: 30px;
    }

   .jedidesk-telegram-welcome-link {
    margin-bottom: 10px;
    cursor: pointer;

    svg{
      transition: transform .5s ease;
    }
    
   }
     .jedidesk-viber-welcome-link {
      margin-bottom: 10px;
      cursor: pointer;
     svg{
      transition: transform .5s ease;
    }
   }

   .jedidesk-telephone-welcome-link {
    margin-bottom: 10px;
    cursor: pointer;
    svg{
      transition: transform .5s ease;
    }
   }

   .jedidesk-email-welcome-link {
     cursor: pointer;
    svg{
      transition: transform .5s ease;
    }
   }

   .jedidesk-telegram-welcome-link:hover,
      .jedidesk-viber-welcome-link:hover,
      .jedidesk-telephone-welcome-link:hover,
      .jedidesk-email-welcome-link:hover { 
        svg {
          -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
                  transform: scale(1.1);
        }
        
      }
}

@media (min-width: 750px) {
  .jedidesk-chat__position:hover {
    .jedidesk-dropdown-second-links {
    opacity: 1;
    transform: translateY(0);
  }
  }
}

@media (max-width: 750px) {
    .jedidesk-chat__position:hover {
        .jedidesk-dropdown-second-links {
          bottom: 100px;
        }
      }
}




/// ////////////////////////////////////////////////////////////////

@keyframes jedidesk-widget-open {
  from {
    transform: scaleY(0);
    transform: translateY(420px);
  }

  to {
    transform: scaleY(1);
    transform: translateY(0);
  }
}

#jedidesk-iframe {
  width: calc(100%);
  height: calc(100%);
  border: none;
}
